import { useEffect, useMemo } from 'react';
import { useEthers } from '@usedapp/core';
import { useAppDispatch, useAppSelector } from './hooks';

//import { contract as AuctionContract } from './wrappers/nounsAuction';
//import { contract as YOLOAuctionContract } from './wrappers/yoloNounsAuction';
//import { contract as YOLOTokenContract } from './wrappers/yoloNounsToken';
import { contract as LabsTokenContract } from './wrappers/labsToken';
import { contract as LabsMinterContract } from './wrappers/labsMinter';

//import { setAuctionEnd } from './state/slices/auction';
//import { setNextNounId } from './state/slices/noun';
//import { setLatestYOLONounId } from './state/slices/yoloNoun';
//import { setBlockAttr } from './state/slices/block';
import { setTotalSupply, setMaxMints, setPaused } from './state/slices/labsToken';

//import { default as config, provider } from './config';

import { Container, Row, Col } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/css/globals.css';
import classes from './App.module.css';

//import NavBar from './components/NavBar';
//import Noun  from './components/Noun';
//import HistoryCollection  from './components/HistoryCollection';
//import Title from './components/Title';
//import MintBar from './components/MintBar';
import Documentation from './components/Documentation';
//import Banner from './components/Banner';
import Footer from './components/Footer';
import AlertModal from './components/Modal';
import MintedTokenModal from './components/MintedTokenModal';
import ShowTokenModal from './components/ShowTokenModal';
import { setAlertModal } from './state/slices/application';

import { setActiveAccount } from './state/slices/account';
//import { openVoteSocket, markVoterInactive } from './middleware/voteWebsocket';
import { openEthereumSocket } from './middleware/alchemyWebsocket';

import heroLogo from './assets/nounish-labs-logo.png';

function App() {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { account } = useEthers();
  const dispatch = useAppDispatch();
  const useGreyBg = useAppSelector(state => state.noun.useGreyBg);
  const missedVotes = useAppSelector(state => state.vote.missedVotes);

  //const latestTokenId = useAppSelector(state => state.labsToken.totalSupply);

  useMemo(async ()=> { // Initalized before mount
  	
    const [totalSupply, maxMints, paused] = await Promise.all([
      LabsTokenContract.totalSupply(),
      LabsMinterContract.maxMints(),
      LabsMinterContract.paused()
    ])

	//labsTokenSupply
	dispatch(setTotalSupply(parseInt(totalSupply)));
	dispatch(setMaxMints(parseInt(maxMints)));
	dispatch(setPaused(paused));
    
  }, [dispatch])

  useEffect(() => {
    dispatch(setActiveAccount(account));
  }, [dispatch, account]);

  useEffect(() => { // Only initialize after mount
    //dispatch(openVoteSocket());
    dispatch(openEthereumSocket());
  }, [dispatch]);

  // Deal with inactive users
  useEffect(() => {
    if (missedVotes > 3) {
      //dispatch(markVoterInactive());
    }
  }, [dispatch, missedVotes]);

  const alertModal = useAppSelector(state => state.application.alertModal);
  
  //Mintbar can be floated in HeroContent area or can be used below the hero area

  return (
    <div className={`${classes.App} ${useGreyBg ? classes.bgGrey : classes.bgBeige}`}>
      {alertModal.show && (
        <AlertModal
          title={alertModal.title}
          content={<p>{alertModal.message}</p>}
          onDismiss={() => dispatch(setAlertModal({ ...alertModal, show: false }))}
        />
      )}
      <ShowTokenModal/>
      <MintedTokenModal/>

      <Container className={classes.heroContainer}>
        <Row className={classes.heroRow2}>
          <Col lg={{ span: 12 }} className={classes.heroContent2}>
          	 <br />
          	 <img src={heroLogo} alt='Nounish Labs Logo' className={classes.heroLogo} />
          </Col>
        </Row>
      </Container>

      <Documentation />
      <Footer/>
    </div>
  );
}

export default App;
