// import Section from '../../layout/Section';
import { Row, Col, Card } from 'react-bootstrap';

import classes from './Documentation.module.css';
//import Accordion from 'react-bootstrap/Accordion';
//import Link from '../Link';

//import config from '../../config';
//import { buildEtherscanContractLink } from '../../utils/etherscan';
import imgComposables from '../../assets/composables-bio.jpg';
import imgYOLO from '../../assets/yolo-bio.jpg';
import imgAuctionTracker from '../../assets/auction-tracker-bio.png';

import imgNoun455 from '../../assets/noun455.svg';
import imgNoun67 from '../../assets/noun67.svg';

const Documentation = () => {

 const nouns = [
  {
    name: 'Noun 455',
    image: imgNoun455,
    url: 'https://nouns.wtf/noun/455',
  },
  {
    name: 'Noun 67 (d)',
    image: imgNoun67,
    url: 'https://nouns.wtf/noun/67',
  },
];

const NounCard: React.FC<{
  name: string;
  image: string;
  url?: string | undefined;
}> = props => {
  const { name, image, url } = props;
  return (
    <>
      <Card.Img variant="top" src={image} />
      <Card.Title>
      	<a href={`${url}`} target="_blank" rel="noreferrer">{name}</a>
      </Card.Title>
    </>
  );
};

const NounCards: React.FC<{ min: number; max: number }> = props => {
  const { min, max } = props;
  return (
    <>
      {nouns.slice(min, max).map(noun => (
        <Col xs={6} md={3} lg={3} className={classes.siteGroup}>
          <NounCard {...noun} />
        </Col>
      ))}
    </>
  );
};
 
 const sites = [
  {
    name: 'Composables',
    image: imgComposables,
    description: "On-chain Nouns framework",
    url: 'https://composables.wtf',
    handle: 'composables_',
  },
  {
    name: 'YOLO Nouns',
    image: imgYOLO,
    description: "Mint a Noun from each block",
    url: 'https://yolonouns.wtf',
    handle: 'yolonouns',
  },
  {
    name: 'Auction Tracker',
    image: imgAuctionTracker,
    description: "DEX for multiple Nounish auctions",
    url: 'https://auctions.wtf',
  }
];

const SiteCard: React.FC<{
  name: string;
  description?: string | undefined;
  image: string;
  url?: string | undefined;
  handle?: string | undefined;
}> = props => {
  const { name, description, image, url, handle } = props;
  return (
    <>
      <Card.Img variant="top" src={image} />
      <Card.Title>
      	<a href={`${url}`} target="_blank" rel="noreferrer">{name}</a>
      </Card.Title>
      {description && <Card.Text>{description}</Card.Text>}
        {handle && (
          <a href={`https://twitter.com/${handle}`} target="_blank" rel="noreferrer">
            <svg
              fill="currentColor"
              viewBox="0 0 20 20"
              aria-hidden="true"
              className={classes.twitterIcon}
              data-v-6cab4e66=""
            >
              <path
                d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84"
                data-v-6cab4e66=""
              ></path>
            </svg>
            {name}
          </a>
        )}

    </>
  );
};

const SiteCards: React.FC<{ min: number; max: number }> = props => {
  const { min, max } = props;
  return (
    <>
      {sites.slice(min, max).map(site => (
        <Col xs={6} md={3} lg={3} className={classes.siteGroup}>
          <SiteCard {...site} />
        </Col>
      ))}
    </>
  );
};

  return (
    <div className={classes.Documentation}>
      <Col lg={{ span: 7, offset: 0 }}>
        <div className={classes.headerWrapper} id="wtf">
          <p style={{ fontWeight: 'bold', textAlign: 'justify' }}>
          
          	<span style={{fontStyle: 'italic'}}>
          	"We have a historic opportunity — to be bold, take risks, and ultimately attract the builders who can take the raw ingredients of the Nouns protocol, and turn them into applications that are ready to be consumed by the world."
          	</span>
          	<br />
          	<a href="https://medium.com/@punk4156/the-nouns-virtuous-cycle-95586bb9c7c9" target="_blank" rel="noreferrer">- 4156, The Nouns Virtuous Cycle</a>
		  </p>
		  <p>
		  	We are a collective of Nounish builders launching new ideas and initiatives across Nouns and the broader CCO ecosystem.
		  </p>
        </div>
      </Col>

      <Col lg={{ span: 7, offset: 0 }} style={{textAlign: 'center'}}>
		<hr />
        <h2 style={{ marginBottom: '0rem' }}>
          nounish experiments
        </h2>
        <h5 style={{ marginBottom: '0rem' }}>          
        	Here are some of the projects we've worked on recently:
        	
        </h5>
        <Row style={{ marginTop: '2rem', marginBottom: '0rem', justifyContent: 'center' }}>
          <SiteCards min={0} max={3} />
        </Row>
      </Col>

      <Col lg={{ span: 7, offset: 0 }} style={{textAlign: 'center'}}>
		<hr />
        <h2 style={{ marginBottom: '0rem' }}>
          nouns
        </h2>
        <h5 style={{ marginBottom: '0rem' }}>          
        	We're active participants in the Nouns DAO via the following Nouns:
        </h5>
        <Row style={{ marginTop: '2rem', marginBottom: '0rem', justifyContent: 'center' }}>
          <NounCards min={0} max={2} />
        </Row>
		<hr style={{ marginLeft: '25%', marginRight: '25%' }} />
      </Col>
    </div>
  );
};

export default Documentation;